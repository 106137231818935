import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '~components/layouts/Default';
import PageHeader from '~components/common/PageHeader';
import Container from '~components/common/Container';
import Calendly from '~components/forms/Calendly';
import P from '~components/typography/P';
import H2 from '~components/typography/H2';
import GridTwo from '~components/common/GridTwo';

export default function Page({ data, location }) {
  return (
    <Layout
      location={location}
      title="☎ Termin buchen - Dein direkter Draht zu uns"
      desc="Du hast Fragen zu Kursen und Seminaren oder möchtest einen Live Call mit uns? Dann buche dir gerne einen Termin. Wir freuen uns auf dich!"
      active="Termin"
    >
      <div className="bg-gray-50">
        <PageHeader title="Terminvereinbarung" breadcrumb={[{ title: 'Terminvereinbarung', link: '/termin/', current: true }]} />
        <Container noMargin className="my-8">
          <div className="relative rounded-xl bg-white">
            <div className="grid grid-cols-1 lg:grid-cols-3">
              <div className="relative overflow-hidden bg-brand-green px-6 py-10 text-brand-gray sm:px-10 xl:p-12">
                <H2 className="mb-5 font-display">Informationen zur Terminvereinbarung</H2>
                <P className="mb-3">Du hast noch Fragen, Wünsche oder Ideen? Dann vereinbare einen Termin. In diesen 30 Minuten beantwortet dir Kursleiter Johannes deine Fragen zu den Entspannungskursen und zum Verfahren selbst.</P>
              </div>

              {/* Contact form */}
              <div className="lg:col-span-2">
                <Calendly />
              </div>
            </div>
          </div>
          <GridTwo className="my-8">
            <div>
              <P className="mb-3">
                Auf dieser Seite kannst du dir einen kostenfreien Beratungstermin sichern! Der Termin kann nach Rücksprache mit dir telefonisch, via Teams oder auch über Zoom stattfinden und soll dir die Möglichkeit geben, deine Fragen zu
                stellen, technische Hürden zu überwinden oder einfach nur mal über das Thema zu quatschen.
              </P>
              <P className="mb-3">
                Seit Corona gibt es viele Onlineangebote und Kurse, die über digitale Lernplattformen stattfinden. Das Angebot der Entspannungshelden besteht seit 2019, daher haben wir bereits einige Erfahrung mit Teilnehmenden gesammelt,
                die sich immer wieder auch einen persönlichen Kontakt zum Kursleiter wünschen.
              </P>
              <P className="mb-3">
                Daher haben wir in unser Angebot ein 20-minütiges Telefonat gesteckt, dass du gerne nutzen darfst! Wir laden dich daher herzlich ein, dir über unsere Calendly App einen für dich passenden Termin zu suchen und zu buchen.
                Teile uns daher grob mit, worum es dir geht. Die Erfahrung hat gezeigt, dass die Teilnehmenden sehr davon profitieren in den direkten Austausch mit Johannes zu treten, da dieser nicht nur gezielt auf deine Fragen eingehen
                kann, sondern fast immer noch weitere Tipps und Tricks aus dem Bereich Entspannung und Achtsamkeit hat, die er gerne mit dir teilt.
              </P>
            </div>
            <div>
              <P className="mb-3">
                Zudem hilfst du uns so aktiv, unser Angebot zu verbessern, weil wir dich um ein Feedback, Verbesserungsvorschläge und ähnliches bitten können und so unser Angebot nach und nach an die Bedürfnisse unserer Kunden anpassen
                können. Das führt manchmal zu kleineren, seltener zu größeren Änderungen, bringt uns und damit auch dich auf jeden Fall weiter!
              </P>
              <P className="mb-3">
                Also, worauf wartest du? Buche jetzt direkt auf dieser Seite deinen Expertencall und stelle all deine Fragen. Das gilt natürlich auch, wenn du noch keinen Kurs gebucht hast, weil du vielleicht noch Fragen zum Ablauf, zur
                Förderung, der Krankenkasse oder ähnliches hast. Um deinen Termin zu sichern, klicke unten einfach auf den Tag und wähle dann die passende Uhrzeit aus. Fülle Name und E-Mail Adresse aus, damit wir dich erreichen können,
                sollten wir noch Rückfragen haben. Bitte vergiss auch nicht dein Anliegen zu schildern, sodass wir entscheiden können, mit wem du besten Falls sprichst.
              </P>
              <P>
                In diesem Falle haben wir dir eine vollumfängliche{' '}
                <Link to="/faq/" className="underline">
                  FAQ
                </Link>{' '}
                zusammengestellt, in dem wir auf die allermeisten Fragen zu den Verfahren, aber auch zum Ablauf des Kurses eingehen und sie beantworten.
              </P>
            </div>
          </GridTwo>
        </Container>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    allFaq {
      nodes {
        text
        frage
        id
      }
    }
    site {
      siteMetadata {
        contact {
          mail
          phone
        }
        social {
          instagram
          facebook
          anchor
        }
      }
    }
  }
`;
